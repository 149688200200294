import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import './Styles/reset.css';
import './Styles/animations.css';
import './Styles/main.css';

import AuthProvider from "./store/auth.provider";
import {ColyseusProvider} from './store/colyseus.provider';

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const Layout = React.lazy(() => import('views/pages/layouts/Layout'));

class App extends Component {

  render() {
    return (
      <ColyseusProvider>
        <AuthProvider>
          <BrowserRouter>
              <React.Suspense fallback={loading}>
                <Switch>
                  <Route path="/" name="Home" render={props => <Layout {...props}/>} />
                </Switch>
              </React.Suspense>
          </BrowserRouter>
        </AuthProvider>
      </ColyseusProvider>
    );
  }
}

export default App;
