// const url_fetch = ''; // DEV
const url_fetch = 'https://outilitri.recyclagevr.com'; // PROD

// API To Call
export const API = {
	WASTE : "waste",
	GARBAGE : "garbage",
	CONSIGNE : "consigne",
	HARDWARE : "hardware",
	GAME_CONFIG : "gameConfig",
	GAME : "game",
	MESSAGE : "message",
	NOTIFICATION : "notification",
	ROLE : "role",
	TIER : "tier",
	USER : "user",
	AUTH : "auth",
}

// GET /api/{dataCall}/:id
export function getOneAPI(dataCall, id, callback, callbackError = () => {}) {
    fetch(url_fetch+'/api/'+dataCall+'/'+id, { method: 'GET'})
    .then(res => { return res.json(); })
    .then(data => {
        callback(data);
    }).catch(err => {
        callbackError({error : err});
    });
}

// GET /api/{dataCall}/
export function getAllAPI(dataCall, callback, callbackError = () => {}) {
    fetch(url_fetch+'/api/'+dataCall+'/', { method: 'GET'})
    .then(res => { return res.json(); })
    .then(data => {
        callback(data);
    }).catch(err => {
        callbackError({error : err});
    });
}

// GET /api/{dataCall}/:id
export function getAllAPIAdmin(dataCall, callback, callbackError = () => {}) {
    fetch(url_fetch+'/api/'+dataCall+'/admin', { method: 'GET'})
    .then(res => { return res.json(); })
    .then(data => {
        callback(data);
    }).catch(err => {
        callbackError({error : err});
    });
}

// POST /api/{dataCall}/
export function createAPI(dataCall, data, callback, callbackError = () => {}) {
    fetch(url_fetch+'/api/'+dataCall+'/', { 
        method: 'POST',
        body: JSON.stringify(data),
        headers: { 'Content-Type': 'application/json' }
    }).then(res => {
        if (res.status === 200) {
            return res.json();
        } else {
            callbackError({error : res.error});
        }
    }).then(dataBack => {
        callback(dataBack);
    }).catch(err => {
        callbackError({error : err});
    });   
}

// UPDATE /api/{dataCall}/
export function updateAPI(dataCall, data, callback, callbackError = () => {}){
    fetch(url_fetch+'/api/'+dataCall+'/', {
        method: 'PUT',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json'
        }
    }).then(res => {
        if (res.status === 200) {
            return res.json();
        } else {
            callbackError({error : res.error});
        }
    }).then(data => {
        callback(data);
    }).catch(err => {
        callbackError({error : err});
    });
}

// DELETE /api/{dataCall}/
export function deleteAPI(dataCall, data, callback, callbackError = () => {}) {
    fetch(url_fetch+'/api/'+dataCall+'/', {
        method: 'DELETE',
        body: JSON.stringify(data),
        headers: {'Content-Type': 'application/json'}
    }).then(res => {
        if (res.status === 200) {
            return res.json();
        } else {
            callbackError({error : res.error});
        }
    }).then(data => {
        callback(data);
    }).catch(err => {
        callbackError({error : err});
    });   
}

// GET /api/{dataCall}/{action}/
export function actionGetAPI(dataCall, action, callback, callbackError = () => {}) {
    fetch(url_fetch+'/api/'+dataCall+'/'+action+'/', { 
        method: 'GET'
    }).then(res => {
        if (res.status === 200) {
            return res.json();
        } else {
            callbackError({error : res.error});
        }
    }).then(dataBack => {
        callback(dataBack);
    }).catch(err => {
        callbackError({error : err});
    });
}

// POST /api/{dataCall}/{action}/
export function actionPostAPI(dataCall, action, data, callback, callbackError = () => {}) {
    fetch(url_fetch+'/api/'+dataCall+'/'+action+'/', { 
        method: 'POST',
        body: JSON.stringify(data),
        headers: { 'Content-Type': 'application/json' }
    }).then(res => {
        if (res.status === 200) {
            return res.json();
        } else {
            callbackError({error : res.error});
        }
    }).then(dataBack => {
        callback(dataBack);
    }).catch(err => {
        callbackError({error : err});
    });   
}

// Delete /api/{dataCall}/{action}/
export function actionDeleteAPI(dataCall, action, callback, callbackError = () => {}) {
    fetch(url_fetch+'/api/'+dataCall+'/'+action+'/', { 
        method: 'DELETE'
    }).then(dataBack => {
        callback(dataBack);
    }).catch(err => {
        callbackError({error : err});
    });
}

// TODO Réflexion à faire sur le local storage
// if(!localStorage.getItem("wasteState")){
//     fetch('/api/waste/', {
//         method: 'GET',
//     }).then(res => {
//         return res.json();
//     }).then(data => {
//         var wastes = JSON.stringify(data.data);
//         var wastesTab = JSON.parse(wastes);
//         var wastesData = JSON.stringify(wastesTab);
//         localStorage.setItem('wasteState', wastesData);
//         context.setState({ wastes: wastesTab });
//     }).catch(err => {
//         context.setState({error : err});
//     });
// } else {
//     context.setState({ wastes: JSON.parse(localStorage.getItem("wasteState")) });
// }