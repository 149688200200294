import React, { useContext } from 'react';
import * as Colyseus from 'colyseus.js';

const ColyseusContext = React.createContext(null);

export const ColyseusProvider = (props) => {
    let client = new Colyseus.Client('wss://outilitri.recyclagevr.com');
    // let client = new Colyseus.Client('ws://192.168.1.203:8080');
    const colyseus = { client: client }

    // client.joinOrCreate("lobby_room").then(room => {
    //     console.log(room.sessionId, "joined", room.name);
    //     room.onStateChange((state) => {
    //       console.log(room.name, "has new state:", state);
    //     });
    //     // room.onMessage("message_type", (message) => {
    //     //   console.log(client.id, "received on", room.name, message);
    //     // });
    //     // room.onError((code, message) => {
    //     //   console.log(client.id, "couldn't join", room.name);
    //     // });
    //     room.onLeave((code) => {
    //         console.log(code);
    //         console.log(client?.id, "left", room?.name);
    //         client = null;
    //     });
    // }).catch(e => {
    //     console.log("JOIN ERROR", e);
    // });

    // client.joinOrCreate("game_room").then(room => {
    //     console.log(room.sessionId, "joined", room.name);
    //     room.onStateChange((state) => {
    //       console.log(room.name, "has new state:", state);
    //     });
    //     // room.onMessage("message_type", (message) => {
    //     //   console.log(client.id, "received on", room.name, message);
    //     // });
    //     // room.onError((code, message) => {
    //     //   console.log(client.id, "couldn't join", room.name);
    //     // });
    //     room.onLeave((code) => {
    //         console.log(code);
    //         console.log(client?.id, "left", room?.name);
    //         client = null;
    //     });
    // }).catch(e => {
    //     console.log("JOIN ERROR", e);
    // });

    const value = {
        colyseus : colyseus
    }

    return(
        <ColyseusContext.Provider value={value}>
            {props.children}
        </ColyseusContext.Provider>
    )
}

export const useColyseus = () => {
    return useContext(ColyseusContext);
}