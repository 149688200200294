import React, { createContext, useEffect, useState } from "react";
import { API, actionGetAPI } from "controllers/Api.controller";

export const AuthContext = createContext({});

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState({ _id : "", name : "", last_name : "", email : "", roles : [], profil_picture : "", tier_logo : "", tier_name : "", tier_tokens : 0});
  useEffect(() => {
    if(user._id === ""){
      actionGetAPI(API.AUTH, "signin", (data) => {
        if(typeof data._id !== "undefined"){
          changeUser(data);
        }
      })
    }
  }, [user])

  const changeUser = (data) => {
    // console.log(data);
    setUser({
      _id : data._id, 
      name : data.name, 
      last_name : data.last_name, 
      email : data.email, 
      roles : data.roles,
      profil_picture : data.profil_picture,
      tier_logo : data.tier_logo,
      tier_name : data.tier_name,
      tier_tokens : data.tier_tokens || 0, 
    });
  }

  const value = {
    user : user,
    setUser : changeUser,
    changeToken : () => {
      // actionGetAPI(API.AUTH, "signin", (data) => {
      //   // console.log(data);
      //   if(typeof data._id !== "undefined"){
      //     setUser({
      //       _id : data._id, 
      //       name : data.name, 
      //       last_name : data.last_name, 
      //       email : data.email, 
      //       roles : data.roles,
      //       profil_picture : data.profil_picture,
      //       tier_logo : data.tier_logo,
      //       tier_name : data.tier_name,
      //       tokens : data.tokens || 0, 
      //     });
      //   }
      // })
      // console.log("change token req");
    }
  }

  // console.log(value);

  return (
    <AuthContext.Provider value={value}>
      {children }
    </AuthContext.Provider>
  );
}

export default React.memo(AuthProvider);